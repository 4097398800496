import React, { useState, FC } from 'react'
import styles from './Reviews.module.scss'
import Image from 'next/image'
import cn from 'classnames'
import { JudgeMeGetReviews } from '../../../interfaces/JudgeMe'
import useWindowDimensions from '../../../customHooks/useWindowDimension'
import PopupImage from '../../Campaigns/MainPageTwo/PopupImage/PopupImage'

type ReviewsProps = {
  data: JudgeMeGetReviews[]
  marginBottom?: boolean
  marginTop?: boolean
  button: string
  text1?: string
  title: string
  text2?: string
  stylesTitle?: boolean
  containerScaling?: string
}

const Reviews: FC<ReviewsProps> = ({
  data,
  marginBottom,
  marginTop,
  button,
  text1,
  title,
  text2,
  stylesTitle,
  containerScaling,
}) => {
  const [openMore, setOpenMore] = useState(data.slice(0, 4))
  const [titleButton, setTitleButton] = useState(button)
  const { width } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(false)
  const [photoPopup, setPhotoPopup] = useState('')

  const [openedReviews, setOpenedReviews] = useState<number[]>([])

  const handleClick = (id: number) => {
    if (openedReviews.includes(id)) {
      setOpenedReviews(openedReviews.filter((item) => item !== id))
    } else {
      setOpenedReviews([...openedReviews, id])
    }
  }

  const onClickLessReview = () => {
    if (titleButton === 'Less reviews') {
      const target = document.getElementById(`reviews`)

      if (target) {
        if (width > 1024 && width < 1550) {
          window.scrollTo({
            top: target.offsetTop - document.documentElement.clientHeight / 2000 - 1800,
            behavior: 'smooth',
          })
        } else {
          window.scrollTo({
            top: target.offsetTop - document.documentElement.clientHeight / 8,
            behavior: 'smooth',
          })
        }
      }
    }
  }

  const onClickButton = () => {
    setOpenMore(data)
    setTitleButton('Less reviews')
    if (titleButton === 'Less reviews') {
      setOpenMore(data.slice(0, 4))
      setTitleButton('More reviews')
    }
    onClickLessReview()
  }

  const onClickPopup = (item: any, number: number) => {
    setIsOpen(true)
    setPhotoPopup(`${item.photo[number]}`)
  }

  const photoReviews = (item: any, number: number) => {
    return (
      <>
        {item.photo[number] && (
          <span
            className={styles.images}
            style={{ backgroundImage: `url(${item.photo[number]})` }}
            onClick={() => onClickPopup(item, number)}
          />
        )}
      </>
    )
  }

  return (
    <section
      className={cn(
        styles.container,
        containerScaling,
        { [styles.containerTwo]: marginBottom },
        { [styles.containerTop]: marginTop }
      )}
    >
      <p className={styles.subHeaderTop}>{text2}</p>
      <h2

        className={cn(styles.header, { [styles.headerBottom]: stylesTitle })}
      >
        {title}
      </h2>
      <p className={styles.subHeader}>{text1}</p>

      <ul className={styles.list}>
        {openMore.map((item: any) => {
          return (
            <React.Fragment key={item.id}>
              <li className={styles.item} id={item.id}>
                <div className={styles.blockUser}>
                  <Image
                    src={item.srcUser}
                    alt={item.name}
                    width={62}
                    height={62}
                    objectFit="contain"
                  />
                  <p className={styles.name}>{item.name}</p>
                </div>
                <p className={styles.text}>
                  {
                    <>
                      {item.text.length < 170 ? item.text : (openedReviews.includes(item.id)
                        ? item.text
                        : item.text.slice(0, 170) + '...')}
                      <span
                        className={styles.linkSpan}
                        onClick={() => handleClick(item.id)}
                      >
                        {item.text.length < 170 ? null : (openedReviews.includes(item.id)
                          ? ' Read less'
                          : ' Read more')}
                      </span>
                    </>
                    }
                </p>
                <div className={cn(styles.blockImage, {[styles.imageTop]:item.photo})}>
                  {item.photo && (
                    <>
                      {photoReviews(item, 0)}
                      {photoReviews(item, 1)}
                      {photoReviews(item, 2)}
                      {photoReviews(item, 3)}
                      {photoReviews(item, 4)}
                    </>
                  )}
                  {item.video && (
                    <iframe
                      width="250"
                      height="300"
                      src={item.video}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
                {/*<p className={styles.date}>{item.date}</p>*/}
              </li>
            </React.Fragment>
          )
        })}
      </ul>
      <div className={styles.blockButton}>
        <button id="button" className={styles.button} onClick={onClickButton}>
          {titleButton}
        </button>
      </div>
      <PopupImage isOpen={isOpen} setIsOpen={setIsOpen} photo={photoPopup} />
    </section>
  )
}

export default Reviews
