import React, { FC } from 'react'
import cn from 'classnames'
import Image from 'next/image'
import styles from './PopupImage.module.scss'

type MyProps = {
  setIsOpen: any
  isOpen: any
  photo: any
}

const PopupImage: FC<MyProps> = ({ setIsOpen, isOpen, photo }) => {
  return (
    <div
      className={cn(styles.popup, styles.popupClub, {
        [styles.popupActive]: isOpen,
      })}
      onClick={(e) =>
          e.currentTarget === e.target && setIsOpen(false)}
    >
      <div className={styles.popupContainer}>
        <div
          className={cn(styles.popupButtonClose, styles.buttonClose)}
          onClick={() => setIsOpen(false)}
        >
          <Image
            src="/images/SleepSupport/close.svg"
            alt="close"
            width={30}
            height={30}
            objectFit="contain"
          />
        </div>

        <img
            className={styles.photo}
            src={photo}
            alt='photo'
            width={62}
            height={62}
        />
      </div>
    </div>
  )
}

export default PopupImage
